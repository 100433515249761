import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import Masonry from "react-masonry-component";
import "react-image-lightbox/style.css";
import pagesData from "../assets/data.json";

const sortByName = (a, b) => {
  if (a.name < b.name) {
    return 1;
  }
  if (a.name > b.name) {
    return -1;
  }
  return 0;
};

export const Gallery = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => {
    const pageData = pagesData[node.name];
    return {
      ...node.childImageSharp,
      name: node.name,
      ...(pageData
        ? {
            active: true,
            ...pageData,
          }
        : { active: false }),
    };
  });
  const sortedImages = images.filter((image) => image.active).sort(sortByName);

  const [index, setIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const prevIndex = (index + sortedImages.length - 1) % sortedImages.length;
  const nextIndex = (index + sortedImages.length + 1) % sortedImages.length;
  //const ImgColWrapper = customWrapper;

  // URLs for full width images
  const mainSrc = sortedImages[index]?.full?.images?.fallback?.src;
  const nextSrc = sortedImages[nextIndex]?.full?.images?.fallback?.src;
  const prevSrc = sortedImages[prevIndex]?.full?.images?.fallback?.src;

  const onCloseLightbox = () => {
    setIsOpen(false);
  };
  return (
    <>
      <Masonry
        className={"my-gallery-class"} // default ''
        style={{
          margin: "auto",
        }}
        options={{
          transitionDuration: 0,
          //gutter: 5,
          fitWidth: true,
          //resize: true,
        }} // default {}
        disableImagesLoaded={false} // default false
        updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
      >
        {sortedImages.map((image, i) => {
          const thumbImage = getImage(image.thumb);
          if (!thumbImage) {
            return null;
          }
          return (
            <div
              style={{
                width: "22%",
                textAlign: "center",
                margin: "10px",
              }}
            >
              <a
                onClick={() => {
                  // TODO route to detail
                }}
              >
                <GatsbyImage
                  objectFit="contain"
                  image={thumbImage}
                  style={{
                    cursor: "pointer",
                    margin: "0 10px",
                  }}
                />
              </a>
              <br />
              <h2 style={{ margin: "5px 0" }}>{image.name}</h2>
              <span>{image.type}</span>
            </div>
          );
        })}
      </Masonry>
      {isOpen && (
        <Lightbox
          mainSrc={mainSrc || ""}
          nextSrc={nextSrc || ""}
          prevSrc={prevSrc || ""}
          onCloseRequest={onCloseLightbox}
          onMovePrevRequest={() => setIndex(prevIndex)}
          onMoveNextRequest={() => setIndex(nextIndex)}
          imageTitle={images[index].title}
          imageCaption={images[index].caption}
        />
      )}
    </>
  );
};
