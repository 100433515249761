import * as React from "react";
import { Layout } from '../components/Layout';
import { Grid } from "@mui/material";
import Seo from "../components/Seo";
import { Gallery } from "../components/Gallery";
import { graphql } from "gatsby";

const IndexPage = ({ data }) => {
  return (
    <Layout
      seo={
        <Seo
          title="Výtvarné portfolio | MgA. Jan Hlavatý"
          keywords={["akryl na plátně", "výtvarné umění", "MgA Jan Hlavatý"]}
          description=""
        />
      }
    >
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={10} sx={{}}>
          <Gallery data={data} />
        </Grid>
      </Grid>
    </Layout>
  );
};

export const query = graphql`
  query FamilyImages {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        relativeDirectory: { eq: "images/gallery" }
      }
      sort: { order: DESC, fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(width: 276, placeholder: BLURRED)
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
          name
        }
      }
    }
  }
`;

export default IndexPage;
